import React from 'react'

import { StyledSocialIcon } from './socialIcon.css.js'
import '../../App.css'

const SocialIcon = ({ href, src }) => {
	return (
    <StyledSocialIcon>
      <a
        href={href}
        className="icon-wrapper"
        target="_blank"
        rel="noopener noreferrer">
        <img
          src={src}
          alt="social icon and link"
          className="" />
      </a>
		</StyledSocialIcon>
	)
}

export default SocialIcon
