import React, { forwardRef, useCallback, useState } from 'react'
import { Card } from 'semantic-ui-react'

import { StyledTileDescription } from './tile-description.css.js'
import { getNestedObject } from '../../lib/helpers'
import '../../App.css'

const TileDescription = ({ info }) => {
  const [height, setHeight] = useState(0)
  const [show, setShow] = useState(false)

  const measuredRef = useCallback(element => {
    const elemHeight = element ? element.getBoundingClientRect().height : 0;

    setHeight(elemHeight)
  }, []);

  const toggleMore = (event) => {
    event.preventDefault();
    setShow(!show)
  }

	// Ideally, would parse and remove <script> tags
	const createMarkup = () => {
		return { __html: getNestedObject(info, ['metadata', 'description']) }
	}

  const CardDescription = forwardRef((props, ref) => {
    return (
      <StyledTileDescription>
        <div ref={measuredRef} className={show ? 'description-expanded' : 'description-collapsed'}>
          <Card.Description
            dangerouslySetInnerHTML={createMarkup()}
            className="card-description"/>
        </div>
        <p
          className="show-toggle"
          style={{ display: height < 100 ? 'none' : 'block', marginTop: '10px' }}
          onClick={toggleMore}>
            {show ? 'Show Less' : 'Show More'}
          </p>
      </StyledTileDescription>
    )
  })

	return <CardDescription />
}

export default TileDescription
