import React from 'react'
import { Radar } from 'react-chartjs-2'

import { PageView } from './view.css.js'
import { mapData } from '../../lib/helpers'

const About = ({ isVisibile, data }) => {
	const styles = {
		display: isVisibile ? 'block' : 'none',
	}

  const graphData = (graph, color) => {
    const data = graph.metadata.graph
		return {
			labels: mapData(data)('title'),
			datasets: [
				{
					backgroundColor: `${color},0.2)`,
					borderColor: `${color},1)`,
					borderWidth: 1,
					hoverBackgroundColor: `${color},0.4)`,
					hoverBorderColor: `${color},1)`,
					data: mapData(data)(
            'metadata',
						'level',
						'metadata',
						'num_val',
					),
				},
			],
		}
	}

  // Too lazy right now to make this a component
  const graphComponent = (graph, data, key) => {
    return (
      <div className="about-section graph-section" key={key}>
        <h1 className="about-header graph-header">{graph.metadata.header}</h1>
        <p>{graph.metadata.intro}</p>
        <div className="graph-wrapper">
          <Radar
            data={data}
            redraw={true}
            options={{
              scale: {
                ticks: {
                  beginAtZero: true,
                  display: false,
                  stepSize: 1,
                },
              },
              legend: {
                display: false,
              },
              maintainAspectRatio: false,
            }}
            width={100}
            height={100}
          />
        </div>
      </div>
    )
  }

  const graphs = () => {
    if (!data) {
      return
    }

    return data.graphsData.map((graph, index) => {
      const colors = ['rgba(255,99,132', 'rgba(54,162,235', 'rgba(255,206,86']

      const data = graphData(graph, colors[index])

      return graphComponent(graph, data, index)
    })
  }

	const logos = () => {
		return data.companiesData.map((item) => {
			return (
				<picture className="flex-item logos" key={item._id}>
					<img src={item.metadata.logo.url} alt="logo" />
				</picture>
			)
		})
	}

	return (
		<PageView className="page-view" style={styles}>
			<div className="section-wrapper section-max-width">
				<div className="about-section">
					<h1 className="about-header">Elevator speech:</h1>
					<p>{data.aboutCopy.about_elevator_speech}</p>
					<p>{data.aboutCopy.about_intro}</p>
				</div>

				{graphs()}

				<div className="about-section">
					<h1 className="about-header">Companies employed by:</h1>
					<div className="flex-container flex-content-around">{logos()}</div>
				</div>
			</div>
		</PageView>
	)
}

export default About
