import styled from 'styled-components'
import { media } from '../../constants.js'

export const PageView = styled.section`
	min-height: 100%;
	width: 0;
	z-index: 5;
	background-color: #fff;
	overflow-x: hidden;
	transition: 0.5s;
	padding-top: 60px;
	text-align: center;

	.section-wrapper {
		margin-left: 30%;
		margin-bottom: 25px;
		padding-left: 60px;
		padding-right: 60px;
		text-align: left;
		background-color: #fff;
	}

  .section-max-width {
    max-width: 620px;
  }

  .page-view {
    width: 100%;
  }

	@media ${media.PHONE} {
		.contact-button {
			width: 100%;
		}
	}

	.contact-form {
		text-align: left;
	}

	.graph-section {
		display: flex;
		justify-content: center;
		flex-direction: column;
	}

	/* TODO: Will need to change for smaller screens */
	.graph-wrapper {
		width: 500px;
		height: 500px;
	}

	@media only screen and (min-width: 320px) and (max-width: 430px) {
		.graph-wrapper {
			width: 300px;
			height: 300px;
		}
	}

	@media only screen and (min-width: 431px) and (max-width: 540px) {
		.graph-wrapper {
			width: 400px;
			height: 400px;
		}
	}

	@media only screen and (min-width: 601px) and (max-width: 680px) {
		.graph-wrapper {
			width: 300px;
			height: 300px;
		}
	}

	@media only screen and (min-width: 681px) and (max-width: 750px) {
		.graph-wrapper {
			width: 350px;
			height: 350px;
		}
	}

	@media only screen and (min-width: 751px) and (max-width: 810px) {
		.graph-wrapper {
			width: 400px;
			height: 400px;
		}
	}

	@media only screen and (min-width: 811px) and (max-width: 900px) {
		.graph-wrapper {
			width: 450px;
			height: 450px;
		}
	}

	.about-header {
		font-size: 20px;
		text-transform: uppercase;
	}

	.graph-wrapper {
		align-self: center;
	}

	.about-section {
		margin-bottom: 30px;
	}

	@media ${media.PHONE} {
		padding-top: 280px;

		.section-wrapper {
			margin-left: 0;
			margin-bottom: 25px;
			padding-left: 10px;
			padding-right: 10px;
			text-align: left;
		}
	}

	#email {
		display: none;
	}
`
