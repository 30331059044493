import React from 'react'

import { PageView } from './view.css.js'
import Tile from '../widgets/tile'

const Portfolio = ({ isVisibile, data }) => {
	const styles = {
		display: isVisibile ? 'block' : 'none',
	}

	const projectList = () => {
		return data.map((item) => {
			return <Tile info={item} key={item._id} />
		})
	}

	return (
		<PageView className="page-view" style={styles}>
			<div className="section-wrapper masonry-grid">{projectList()}</div>
		</PageView>
	)
}

export default Portfolio
