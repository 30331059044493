import styled from 'styled-components'

export const StyledFooter = styled.footer`
	z-index: 3;
  height: 60px;
  position: fixed;
  bottom: 20px;
  width: 100%;
  display: flex;
  justify-content: center;

  .scrim {
    background-color: rgba(0, 0, 0, 0.2);
    box-shadow: 0 0 5rem rgba(0, 0, 0.5);
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 30px;
	}
`
