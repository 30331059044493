import React from 'react'
import { Card, Label } from 'semantic-ui-react'

import TileDescription from './tile-description'
import { StyledTile } from './tile.css.js'
import { getNestedObject } from '../../lib/helpers'
import '../../App.css'

const Tile = ({ info }) => {
	const getTags = () => {
		const languagesArr = getNestedObject(info, ['metadata', 'languages'])
		const languages = languagesArr.map((item) => {
			return item.title
		})

		const technologiesArr = getNestedObject(info, ['metadata', 'technologies'])
		const technologies = technologiesArr.map((item) => {
			return item.title
		})

		const tags = languages.concat(technologies)

		return tags.map((tagName) => (
			<Label className="portfolio-tag" key={tagName}>
				{tagName}
			</Label>
		))
	}

	return (
    <StyledTile>
  		<Card>
  			<picture>
  				<img
  					src={getNestedObject(info, ['metadata', 'main_image', 'url'])}
  					alt="main project representation"/>
  			</picture>
  			<Card.Content>
  				<Card.Header>{getNestedObject(info, ['title'])}</Card.Header>

          <TileDescription info={info} />
  			</Card.Content>
  			<Card.Content extra className="flex-container">
  				<Label.Group color="black">{getTags()}</Label.Group>
  			</Card.Content>
  		</Card>
    </StyledTile>
	)
}

export default Tile
