import styled from 'styled-components'

export const StyledTileDescription = styled.div`
  .description-collapsed {
    max-height: 100px;
    overflow: hidden;

    .card-description {
      /* For ellipsis to work */
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
      -webkit-line-clamp: 5;
    }
  }

  .description-expanded {
    max-height: 100%;
  }

  .card-description {
    word-break: break-word;
  }

  .show-toggle {
    cursor: pointer;
    color: #3790AD;
  }
`
