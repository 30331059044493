import React, { Component } from 'react'
import Cosmic from 'cosmicjs'

import About from './components/containers/About'
import Contact from './components/containers/Contact'
import Header from './components/widgets/header'
import Idioms from './components/containers/Idioms'
import Portfolio from './components/containers/Portfolio'
import Footer from './components/widgets/footer'

import { StyledApp } from './app.css.js'

import './App.css'

class App extends Component {
	state = {
		cmsBucket: {},
		aboutData: {
      aboutCopy: {},
      graphsData: [],
      companiesData: [],
    },
		idiomsData: [],
		portfolioData: [],
    socialLinksData: [],
		visibleSection: '',
	}

	componentDidMount = async () => {
		/*
		** If I choose to use cosmicjs
		*/
		const portfolioBucket = Cosmic().bucket({
			slug: process.env.REACT_APP_COSMIC_BUCKET_NAME,
			read_key: process.env.REACT_APP_COSMIC_READ_KEY,
			write_key: process.env.REACT_APP_COSMIC_WRITE_KEY,
		})

		const cmsBucket = await portfolioBucket.getBucket()

    const projects = await portfolioBucket.getObjectsByType({
      type_slug: 'projects',
    })
    const portfolioData = projects.objects

    const socialLinks = await portfolioBucket.getObjectsByType({
      type_slug: 'sociallinks',
    })
    const socialLinksData = socialLinks.objects

		const graphs = await portfolioBucket.getObjectsByType({
			type_slug: 'graphs',
		})
		const graphsData = graphs.objects

		const companies = await portfolioBucket.getObjectsByType({
			type_slug: 'companies',
		})
		const companiesData = companies.objects

		const aboutContent = await portfolioBucket.searchObjectType({
			type_slug: 'pages',
			metafield_key: 'page_meta',
			metafield_value: 'about_page',
		})
		const aboutCopy = aboutContent.objects[0].metadata
		const aboutData = {
      aboutCopy,
      graphsData,
      companiesData,
		}

		const idioms = await portfolioBucket.getObjectsByType({
			type_slug: 'idioms',
		})
		const idiomsData = idioms.objects

		return this.setState({ cmsBucket, aboutData, portfolioData, idiomsData, socialLinksData })
	}

	clickHandler = (event) => {
		const targetElement = event.target.getAttribute('data-view-visibility')

		//TODO Do I care about scrolling to top??
		// console.log('event.target', event.target)
		// const sectionsArr = document.querySelectorAll('section')
		// console.log('sectionsArr', sectionsArr)
		// sectionsArr.map((section) => section.scrollTo(0, 0))
		return this.setState({ visibleSection: targetElement })
	}

	render() {
		return (
			<StyledApp className="App">
				<section className="home-view">
					<div className="home-picture" />
				</section>

				<Header clickHandler={this.clickHandler} />

				<main className="main-view">
					<About
						data={this.state.aboutData}
						isVisibile={this.state.visibleSection === 'ABOUT'}
					/>
					<Portfolio
						data={this.state.portfolioData}
						isVisibile={this.state.visibleSection === 'PORTFOLIO'}
					/>
					<Idioms
						data={this.state.idiomsData}
						isVisibile={this.state.visibleSection === 'IDIOMS'}
					/>
					<Contact isVisibile={this.state.visibleSection === 'CONTACT'} />
				</main>

				<Footer data={this.state.socialLinksData} />
			</StyledApp>
		)
	}
}

export default App
