import styled from 'styled-components'
import { media } from './constants.js'

export const StyledApp = styled.main`
	text-align: center;
	display: flex;

	.App-logo {
		animation: App-logo-spin infinite 20s linear;
		height: 80px;
	}

	.home-view {
		position: fixed;
		bottom: 60px;
		display: flex;
		width: 100%;
		height: 20px;
		justify-content: center;
	}

	.home-tag-line {
		margin-left: 43px;
		z-index: 1;
	}

	.main-view {
		width: 130%;
		display: flex;
		margin-left: -30%;
		height: 100vh;
	}

	@media ${media.PHONE} {
		.main-view {
			width: 100%;
			margin-left: 0;
		}
	}

  .page-view {
    width: 100%;
  }

	.flex-container {
		display: flex;
		flex-wrap: wrap;
	}

	.masonry-grid {
		column-count: 4;
		column-gap: 1em;
	}

	@media (max-width: 800px) {
		.masonry-grid {
			column-count: 1;
		}
	}

	@media (min-width: 801px) and (max-width: 1100px) {
		.masonry-grid {
			column-count: 2;
		}
	}

	@media (min-width: 1101px) and (max-width: 1400px) {
		.masonry-grid {
			column-count: 3;
		}
	}

	.full-height {
		height: 100%;
	}

	.flex-content-around {
		justify-content: space-around;
	}

	.flex-item,
	.ui.card,
	.ui.cards > .card {
		display: inline-block;
		width: 100%;
		margin: 10px 0;
	}

	.portfolio-tag {
		margin: 5px;
	}

	.logos {
		min-width: 100px;
		max-width: 150px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		margin: 15px;
	}

	ul {
		list-style: none;
	}

	img {
		width: 100%;
	}

	.App-title {
		font-size: 1.5em;
	}

	.App-intro {
		font-size: large;
	}

	@keyframes App-logo-spin {
		from {
			transform: rotate(0deg);
		}
		to {
			transform: rotate(360deg);
		}
	}
`
