import React from 'react'

import SocialIcon from './socialIcon'
import { StyledFooter } from './footer.css.js'
import '../../App.css'

const Footer = ({ data }) => {
  const socialLinks = () => {
    return data.length > 0
      ? data.map((linkObj) => {
          return (
            <SocialIcon href={linkObj.metadata.url} src={linkObj.metadata.logo.url} key={linkObj.title}/>
          )
        })
      : <div></div>
  }

	return (
		<StyledFooter className="app-footer">
      <div className="scrim">{socialLinks()}</div>
		</StyledFooter>
	)
}

export default Footer
